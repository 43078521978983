const initialQuestionsState: any = null

const setQuestion: "setQuestion" = "setQuestion"


function questionsReducer(questionsState: any, action: any) {
    switch (action.type)
    {
        case setQuestion:
            return action.data
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}


export {initialQuestionsState, questionsReducer}