function urlify(text: string)
{
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a style="text-decoration: underline; color: aqua" href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}


export default urlify
