import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";

export default function PaginationButton({ totalPages, setCurrentPage }: { totalPages: number, setCurrentPage: React.Dispatch<React.SetStateAction<number>> }) {
  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1)
    window.scrollTo({
      top: 0,
      behavior: `smooth`
    })
  }
  return (
    <div>
      <ReactPaginate
        breakLabel={
          <span className="mr-4">...</span>
        }
        nextLabel={
          <span className="w-10 h-10 flex items-center justify-center bg-c-G rounded-md">
            <BsChevronRight />
          </span>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        marginPagesDisplayed={2} 
        pageCount={totalPages}
        previousLabel={
          <span className="w-10 h-10 flex items-center justify-center bg-c-G rounded-md mr-4">
            <BsChevronLeft />
          </span>
        }
        containerClassName="flex items-center justify-center mt-8 mb-4"
        pageClassName="block border-solid border-black hover:bg-c-F hover:text-c-G w-10 h-10 flex items-center justify-center rounded-md mr-4"
        activeClassName="bg-c-F text-c-G font-semibold"
      />
    </div>
  )
}
