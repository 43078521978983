import StandOutQuestionList from "./StandOutQuestionList";

export default function SideBar()
{
    return (
        <div className={`md:flex md:flex-row md:mr-2 w-full flex flex-col`}>
            <StandOutQuestionList />
        </div>
    );
}
