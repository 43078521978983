import {useEffect, useState} from "react";

import Post from "../components/Post";
import CommentContainer from "../containers/CommentContainer";

import {useParams} from "react-router-dom"

import {getOne} from "../../utils/MyAxios"


export default function PostPage()
{
    const {postId} = useParams()
    const [post, setPost] = useState<any>(null)


    useEffect(() => {
        getOne("posts", postId)
            .then((res) => {
                setPost(res.data)
            })
            .catch((err) => {
                return;
            })
    }, [])


    return (
        <div className={"relative w-full flex flex-col gap-7 lg:max-w-[700px] xl:max-w-full xl:w-full border shadow-md rounded-md h-full"}>
            {
                post
                ?   <>
                    <Post {...post}/>
                    <CommentContainer postId={post.id} className={"ml-2 md:ml-2 "}/>
                    </>
                : <h1>Loading...</h1>
            }
        </div>
    );
}
