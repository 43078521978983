import QuestionsProvider from "../../store/questions/QuestionsProvider";

import PostCardContainer from "../containers/PostCardContainer"


export default function DiscussionPage()
{
    return (
        <div className={"mx-auto w-full lg:max-w-[700px]  xl:max-w-full"}>
            <QuestionsProvider>
                <PostCardContainer/>
            </QuestionsProvider>

        </div>
    );
}
