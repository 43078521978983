import {useLayoutEffect, useRef} from "react";
import Avatar from "../core/Avatar";
import Button from "../core/Button";
import eventEmitter from "../../utils/EventEmitter";
import censorEmail from "../../utils/handleEmail";
import {BsFillReplyAllFill } from "react-icons/bs"
import formatDate from "../../utils/FormatDate";
export default function Comment(props: any)
{
    const replyButton = useRef<any>(null)


    useLayoutEffect(() => {

        const currentTriggerEditorButton = replyButton.current

        currentTriggerEditorButton.addEventListener('click', () => {
            eventEmitter.emit('editor-up', {action: props.action, on: props.id, to: props.user.email, at: props.commentId})
        })

        return () => {
            document.removeEventListener('click', currentTriggerEditorButton)
        }

    }, [])


    return (
        <article
            id={props.id}
            className={`
                bg-white 
                hover:bg-white
                duration-300 -mb-4
                flex gap-3 flex-col
                ${props.className}
                sm:flex-row sm:items-start
  `}
        >
            <Avatar name={props.user.email} />
            <section className={"w-full sm:w-10/12"}>
                <div className="bg-c-G rounded-xl items-center p-2 text-[11px]">
                    <span className={"text-sm font-semibold pl-2"}>
                        {censorEmail(props.user.email)}
                    </span>
                    <div className={"lg:text-[14px] pl-2"}>{props.content}</div>
                </div>
                <span className={"flex -mt-2 items-center justify-between sm:justify-start"}>
                    <Button
                        ref={replyButton}
                        buttonType={"icon-border"}
                        className={"flex gap-1 duration-300 "}
                    >
                        <span>
                            <BsFillReplyAllFill className="text-black font-bold" />
                        </span>
                        <span className="text-sm">Reply</span>
                    </Button>
                    <span className={"text-gray-400 text-[12px] sm:text-[12px] pr-2"}>
                        {formatDate(props.createdAt)}
                    </span>
                </span>
            </section>
        </article>

    );
}
