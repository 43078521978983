import { BsFillArrowDownCircleFill, BsFillBookmarksFill, BsFillQuestionCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function StandOutQuestionList()
{
    return (
        <div className="flex flex-col font-semibold md:mt-7 mt-1 overflow-hidden bg-white sm:border md:border-0   border sm:bg-white sm:mx-2 sm:w-full rounded-2xl md:w-full md:mx-0 md:rounded-none">
           
            <a href="https://swapbrain.com/instruction-how-to-download-and-use-the-pinyintube-chrome-extension/" className="flex gap-2 items-center duration-300 p-4 hover:text-white hover:bg-c-F py-4 first:border-0 group">
                <div>
                    <BsFillBookmarksFill className="text-[26px] text-c-F group-hover:text-white " />
                </div>
                <span className="text-[14px]">How to use PinyinTube</span>
            </a>

            <a href="https://chrome.google.com/webstore/detail/pinyintube/dkhngpdmjgnpjjbofhioackbnfponcgh" className="flex gap-2 items-center duration-300 p-4 hover:text-white hover:bg-c-F py-4 first:border-0 group">
                <div>
                    <BsFillArrowDownCircleFill className="text-[28px] text-c-F  group-hover:text-white " />
                </div>
                <span className="text-[14px]">Download PinyinTube now</span>
            </a>


            <Link to="/faq" className="flex gap-2 items-center duration-300 p-4 hover:text-white hover:bg-c-F py-4 first:border-0 group">
                <div>
                    <BsFillQuestionCircleFill className="text-[28px] text-c-F group-hover:text-white " />
                </div>
                <span className="text-[14px]">Frequently Asked Questions</span>
            </Link>

          
        </div>

    );
}
