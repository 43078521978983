import React from "react";

interface FAQItem {
    question: string;
    answer: string;
}

const FrequentlyAskQuestions: React.FC = () => {
    const faqData: FAQItem[] = [
        {
            question: "1. What is Pinyin?",
            answer: "Pinyin is a method for writing Chinese characters in the Latin alphabet (the alphabet used by English and most European languages). For example, in Chinese characters, Shanghai is written 上海. The pinyin – “shàng hǎi” – tells you how to pronounce those characters."
        },
        {
            question: "2. Why use Pinyin?",
            answer: "Pinyin is used for several purposes. It can help learners of Chinese (including native speakers) to read Chinese characters and/or words. It can be used to transcribe names and places into words accessible to Romanized language speakers, and it can be used as an input method for typing Chinese characters."
        },
        {
            question: "3. Can I translate Chinese characters to Pinyin?",
            answer: "Yes, our PinyinTube extension will automatically display an additional Pinyin translation whenever you choose the Chinese subtitle on Netflix or Youtube."
        },
        {
            question: "4. How accurate is the Pinyin pronunciation?",
            answer: "Native speakers of English tend to produce fairly accurate pronunciations when reading pinyin, with exceptions usually occurring with phonemes not generally found in English, spelled with characters usually associated with divergent English pronunciations: j /tɕ/, q /tɕʰ/, x /ɕ/, z /ts/, c /tsʰ/, zh /ʈʂ/, ch /ʈʂʰ/, h /x/ and r /ɻ/ exhibit the greatest discrepancies. When seeing these consonants, English speakers should pay attention and learn to pronounce them the Chinese way."
        },
        {
            question: "5. Can I translate Pinyin to other languages?",
            answer: "Yes, you can use Google Translate website and type in pinyin. Google translate will automatically convert pinyin to the chinese characters and then to your target language."
        },
        {
            question: "6. Which languages are supported for translation from Pinyin?",
            answer: "The Chinese (including Pinyin) subtitles can be translated to many popular languages. Please click on the dual subtitle button to choose a target language."
        },
        {
            question: "7. Is the translation contextually accurate?",
            answer: "Currently the second subtitle is translated from the first subtitle by a rough method. The translation is word-to-word and might not be contextually accurate."
        },
        {
            question: "8. Can I use this tool for language learning?",
            answer: "Yes, this tool is designed to let you learn language while having fun and getting immersed into your favorite movie. "
        },
        {
            question: "9. Is this tool suitable for professional translation?",
            answer: "Currently the second subtitle is translated from the first subtitle by a rough method. The translation is word-to-word and might not be professionally appropriate. "
        },
        {
            question: "10. How do I use the translation tool?",
            answer: "Click on the dual subtitle button “CC”  on the right side of the video. "
        },
        {
            question: "11. Is this tool free to use?",
            answer: "The tool can be downloaded and used for free with some limited features like sentence repeating, dual subtitle, and pinyin subtitle. In order to use our premium features like voice recorded,  please purchase the Premium version by clicking on the extension pop-up."
        },
        {
            question: "12. Is my data secure?",
            answer: "The payment is handled by Stripe platform, which is a trusted payment company worldwide. Your payment data will be securely handled. "
        },
        {
            question: "13. Can I use this tool on mobile devices?",
            answer: "At the moment, the extension can only be installed on Chrome browser on the desktop. The mobile Chrome version is not yet tested. "
        },
        {
            question: "14. Is there a character limit for translation?",
            answer: "Most sentences will be adequately translated. "
        },
        {
            question: "15. How can I provide feedback or report issues?",
            answer: "Please provide feedback by making a post on this forum,  or sending us a private message using the “Contact Form” (add hyperlink to contact form) . Please remember to enter a correct email address so that we can get back to you. "
        },
       
    ];

    return (
        <div className="bg-white min-h-screen py-4">
            <div className="max-w-3xl mx-auto p-4">
                <h1 className="text-3xl font-semibold mb-6 text-center">Frequently Asked Questions</h1>
                <ul>
                    {faqData.map((item, index) => (
                        <li key={index} className="mb-8">
                            <h2 className="text-xl font-semibold mb-2">{item.question}</h2>
                            <p className="text-gray-600 text-justify">{item.answer}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FrequentlyAskQuestions;
