import {useEffect, useState} from "react";
import Comment from "../components/Comment";
import ReplyContainer from "./ReplyContainer";

import {getOne} from "../../utils/MyAxios"

import eventEmitter from "./../../utils/EventEmitter"



export default function CommentContainer(props: any)
{
    const [comments, setComments] = useState<any[]>([])
    const postId = props.postId
    
    
    useEffect(() => {
        getOne("posts", postId)
            .then((res) => {
                console.log("Comments from API:", res.data.comments);
                setComments(res.data.comments);
            })
            .catch((error) => {
                console.error("Error fetching comments:", error);
            });
    }, []);



    useEffect(() =>
    {
        const listener = eventEmitter.addListener("reload-comment", () => {
            getOne("posts", postId)
                .then((res) => {
                    setComments(res.data.comments)
                })
        })

        return () => {
            listener.removeListener("reload-comment")
        }

    }, [])

    
    return (
        <div className={props.className + " flex flex-col gap-7 border-t md:-ml-12 pt-2"}>
            {
                comments.length !== 0
                ? comments.map((comment: any, index: number) => {
                    return (
                        <section key={comment.id} className={"relative group  "}>
                            <Comment action={"reply"} commentId={comment.id} {...comment}/>
                            <ReplyContainer {...comment} className={" before:duration-300 before:absolute  before:w-[2px] sm:before:w-[3px] before:left-0 before:inset-y-0 pl-4 sm:pl-6 lg:pl-8"}/>
                        </section>
                    )
                  })
                    : <p>There are no comments yet</p>
            }
        </div>
    );
}
