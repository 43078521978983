import axios from "axios";
import { useEffect, useState } from "react";


export default function EventCard() {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://swapbrain.com/wp-json/wp/v2/posts?per_page=5`);
            setData(response.data);
            console.log(loading);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {loading ? (
                <div className="animate-pulse flex flex-col items-center">
                    <div className="rounded-xl bg-gray-300 w-[140px] xl:w-full h-[140px] xl:h-[200px]"></div>
                    <div className="mt-4 bg-gray-300 h-6 w-3/4 rounded"></div>
                </div>
            ) : data && data.length > 0 && Array.isArray(data) ? (
                data.map((data: any) => {
                    return (
                        <a href={data.link}>
                            <article className="bg-slate-50 flex flex-col gap-2 xl:gap-3 xl:flex-col hover:bg-gray-200 items-center p-4 rounded-2xl hover:scale-105">
                                <img
                                    className="rounded-xl w-[140px] xl:w-full transition duration-300 hover:brightness-110"
                                    src={data.jetpack_featured_media_url}
                                    alt=""
                                />
                                <section className="text-center">
                                    <p className="limit-2 xl:limit-3 font-semibold overflow-hidden">{data.title.rendered}</p>
                                </section>
                            </article>
                        </a>
                    );
                })
            ) : (
                <h1>No blog</h1>
            )
            }
        </div>
    );
}