import React from "react";


interface ButtonProps
{
    label?: any;
    children?: any;
    disable?: boolean;
    buttonType: string;
    type?: string;
    className?: string;
    onClick?: Function;
}

const ButtonType =
{
    "icon": "text-xl",
    "icon-border": "text-[18px] p-3 rounded-xl",
    "outline": "text-[12px] py-[7px] px-5 border border-white rounded-3xl",
    "primary": "bg-c-D p-3 rounded-3xl"
};

export default React.forwardRef((props: ButtonProps, ref: any) =>
{
    return (
        <button
            onClick={props.onClick as any}
            ref={ref}
            type={props.type || "button" as any}
            className={`
                    ${props.className}
                    ${ButtonType[props.buttonType as keyof typeof ButtonType]}
                `}
            disabled={props.disable}>
            {props.children || props.label}
        </button>
    );
})
