export default function Logo()
{
    return (
        <article className=" pl-5">
            <section className="">
                <img
                    src="https://swapbrain.com/wp-content/uploads/2023/04/Swapbrain-logo.png"
                    alt="SWAPBRAIN Logo"
                    className="w-12 sm:w-12 md:w-16 lg:w-18 xl:w-18"
                />
            </section>
        </article>
    );
}
