import Header from "../components/Header";
import EventCardContainer from "../containers/EventCardContainer";
import SideBar from "../components/SideBar";
import PostEditor from "../components/PostEditor";

import {Outlet} from "react-router-dom"


export default function MainLayout()
{
    return (
        <main className={`relative `}>
            <Header />
            <section className={"items-start pt-[6rem] lg:grid lg:grid-cols-[280px_1fr] lg:gap-10 xl:m-auto xl:w-[1200px] 2xl:w-[1400px]"}>
                <div className="border-r-2 h-full md:ml-0">
                    <SideBar />
                </div>
                <div className={"flex items-center xl:items-start flex-col xl:grid xl:gap-10 xl:grid-cols-[auto_230px]"}>
                    <Outlet />
                    <EventCardContainer />
                </div>
            </section>

            <PostEditor />

        </main>
    );
}
