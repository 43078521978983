interface TextAreaProps
{
    label?: string;
    children?: any;
    className?: any;
    type: string;
    placeHolder?: string;
    onChange?: Function;
    defaultValue?: string
}

const TextAreaType =
    {
        "with-icon": "py-3 px-4"
    }

export default function TextArea(props: TextAreaProps)
{
    return (
        <label htmlFor="" className={`
            ${props.className}
            ${TextAreaType[props.type as keyof typeof TextAreaType]}
            flex flex-col gap-1 bg-c-H rounded-3xl border border-gray-300
        `}>
            {props.children}
            <textarea onChange={props.onChange as any} className={"bg-transparent border-0 float-right w-full outline-0 text-[13px]"} rows={10} placeholder={props.placeHolder} defaultValue={props.defaultValue} />
        </label>
    );
}
