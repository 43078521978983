import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import "./style/__index.scss";

import MainLayout from "./app/layouts/MainLayout";
import PostPage from "./app/pages/PostPage";
import DiscussionPage from "./app/pages/DiscussionPage";

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import FrequentlyAskQuestions from './app/components/FrequentlyAskQuestions';
import NotFoundPage from './app/pages/NotFoundPage';


const root = ReactDOM.createRoot
(
  document.getElementById('root') as HTMLElement
);


root.render(
  <>
      <BrowserRouter>
          <Routes>

              <Route path={"/"} element={<MainLayout/>}>
                    <Route index element={<DiscussionPage />} />
                    <Route path='faq' element={<FrequentlyAskQuestions/>}></Route>
                    <Route path={"posts/:postId"} element={<PostPage/>}/>
                    <Route path={"*"} element={<NotFoundPage/>}/>
              </Route>

          </Routes>
      </BrowserRouter>
  </>
);


reportWebVitals();
