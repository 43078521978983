export default function Navigation()
{
    return (
        <nav className={`
            flex flex-col h-fit
            ml-8
            `}>
            <ul className={`
               flex gap-[25px] uppercase
                 text-black text-base font-semibold
                xl:gap-[50px] xl:text-[16px]
            `}>
                <li>
                    <a href="https://swapbrain.com/">Home</a>
                </li>
                <li className={`
                    text-black relative
                    before:block before:absolute before:w-1 before:h-1 before:bg-c-F before:rounded-2xl
                    before:top-7 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2
                    `}>
                    <a href={process.env.REACT_APP_DOMAIN}>
                        Forum
                    </a>
                </li>
                <li>
                    <a href="https://swapbrain.com/business/">About Us</a>
                </li>
            </ul>
        </nav>
    );
}
