import axios from "axios";

function getMany(api: string)
{
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/${api}`)
}

function getOne(api: string, id: any, query: any = {})
{
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/${api}/${id}`, {params: query})
}

function post(api: string, data: any, query: any = {})
{
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${api}`, data,{params: query})
}

export {getMany, getOne, post}