import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import PostCard from "../components/PostCard";
import QuestionsContext from "../../store/questions/QuestionsContext";
import { getMany } from "../../utils/MyAxios";
import Avatar from "../core/Avatar";
import Button from "../core/Button";
import { BsFillCapslockFill } from "react-icons/bs";
import eventEmitter from "../../utils/EventEmitter";
import PaginationButton from './../components/PaginationButton';
import RankPosts from "../components/RankPosts";

export default function PostCardContainer() {
    const [questions, dispatchQuestions] = useContext(QuestionsContext)
    const [loading, setLoading] = useState(true);
    const triggerEditorButton = useRef<any>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 20;
    const [posts, setPosts] = useState<any[]>([])

    const sortPinnedPosts = (posts: any[]) => {
        const pinnedPosts = posts.filter(post => post.isPinned);
        const nonPinnedPosts = posts.filter(post => !post.isPinned);

        pinnedPosts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setPosts([...pinnedPosts, ...nonPinnedPosts]);
        dispatchQuestions({ type: "setQuestion", data: [...pinnedPosts, ...nonPinnedPosts] })
    };

    useEffect(() => {
        getMany("posts")
            .then(res => {
                sortPinnedPosts(res.data);
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        const listener = eventEmitter.addListener("reload-posts", () => {
            getMany("posts")
            .then(res => {
                sortPinnedPosts(res.data);
                setLoading(false)
            })
        })

        return () => {
            listener.removeListener("reload-posts")
        }

    }, [])

    useLayoutEffect(() => {
        const currentTriggerEditorButton = triggerEditorButton.current;
        currentTriggerEditorButton.addEventListener("click", () => {
            eventEmitter.emit("editor-up", { action: "post" });
        });

        return () => {
            document.removeEventListener("click", currentTriggerEditorButton);
        };
    }, []);

    const handleInputClick = () => {
        triggerEditorButton.current.click();
    };

    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = useMemo(() => posts.slice(firstPostIndex, lastPostIndex), [posts, firstPostIndex, lastPostIndex]);
    const totalPages = useMemo(() => Math.ceil(posts.length / postsPerPage), [posts, postsPerPage]);

    return (
        <div className="md:mb-1 mb-20 ">
            <div className="block mt-4 mb-8 border p-2 md:p-4 m-auto bg-white rounded-2xl md:rounded-md mx-2">
                <div className="flex flex-row items-center">
                    <Avatar className="md:mr-2" />
                    <div className="flex-1 md:w-[320px] ml-2 ">
                        <input
                            type="text"
                            onClick={handleInputClick}
                            className="font-normal py-2.5 text-[13px] px-4 text-gray-400 border hidden md:block rounded-md w-full"
                            value="Share your feedback here..."
                        />
                    </div>
                    <div className="relative">
                        <div className="md:ml-1">
                            <Button
                                ref={triggerEditorButton}
                                buttonType={""}
                                className="font-semibold items-end bg-c-F rounded-3xl md:py-2.5 p-2  my-auto md:px-4 md:ml-4 md:mt-0"
                                label={
                                    <>
                                        <BsFillCapslockFill className="inline-block text-white text-xs md:text-sm" />
                                        <span className="ml-1 text-white text-xs hidden md:inline md:text-[13px]">New discussion</span>
                                        <span className="inline md:hidden text-white text-sm"> New</span>
                                    </>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            {!loading && currentPosts.length > 0 ? (
                <RankPosts questions={questions} posts={posts} setPosts={setPosts} />
            ) : null
            }

            <div className="flex flex-col w-full gap-4">
                {loading ? (
                    <div className="relative">
                        <div className="animate-spin mx-auto my-auto rounded-full h-6 w-6 border-t-4 border-c-F"></div>
                    </div>
                ) : currentPosts && currentPosts.length > 0 && Array.isArray(currentPosts) ? (
                    currentPosts.map((data: any) => {
                        return (
                            <div key={data.id}>
                                <PostCard
                                    id={data.id}
                                    email={data.user.email}
                                    createdAt={data.createdAt}
                                    title={data.title}
                                    content={data.content}
                                    commentCount={data.comments.length}
                                    isPinned={data.isPinned}
                                />

                            </div>
                        );
                    })
                )
                    : (
                        <h1>No data available</h1>
                    )}
            </div>
            <br />
            {!loading && currentPosts.length > 0 ? (
                <PaginationButton totalPages={totalPages} setCurrentPage={setCurrentPage} />
            ) : null
            }
        </div>
    );

}
