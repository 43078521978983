import { useState } from "react";
import Logo from "../core/Logo";
import Button from "../core/Button";
import Input from "../core/Input";
import Navigation from "./Navigation";
import { BsJustify, BsX } from "react-icons/bs";

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleMobileMenuClose = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <div>
            <header className="fixed z-30 top-0 left-0 right-0 bg-white shadow py-3 flex flex-row items-center md:px-10">
                <a className="w-1/6 md:w-1/12 lg:w-1/12" href={process.env.REACT_APP_DOMAIN}>
                    <Logo />
                </a>

                <div className="hidden lg:block xl:block 2xl:block w-1/3">
                    <Navigation />
                </div>

                <section className="w-4/5 md:w-2/3 flex items-center gap-2 sm:gap-4 md:gap-12 justify-end">
                    <Input
                        className="w-2/3 sm:w-2/3 md:w-4/5 md:flex text-gray-800 hidden"
                        placeHolder="Search in the Swap Brain forums"
                        inputType="with-icon"
                    >
                    </Input>

                    <a href="https://chrome.google.com/webstore/detail/pinyintube/dkhngpdmjgnpjjbofhioackbnfponcgh">
                        <Button
                            className="sm:flex bg-c-F text-white mr-2 h-9 font-semibold text-xs md:text-sm md:h-10 items-center "
                            buttonType="outline"
                        >
                            PRODUCT
                        </Button>
                    </a>

                    <Button className="lg:hidden flex" buttonType="icon" onClick={handleMobileMenuToggle}>
                        {isMobileMenuOpen ? <BsX /> : <BsJustify />}
                    </Button>
                </section>
            </header>

            {isMobileMenuOpen && (
                <div className="lg:hidden flex flex-col z-50 absolute w-full items-center bg-slate-50 py-2 mt-4 ">
                    <Navigation/>
                    <Button className="mt-4" buttonType="icon" onClick={handleMobileMenuClose}>
                        <BsX />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Header;
