import { useLayoutEffect, useRef } from "react";
import Avatar from "../core/Avatar";
import Button from "../core/Button";
import eventEmitter from "../../utils/EventEmitter";
import censorEmail from "../../utils/handleEmail";
import formatDate from "../../utils/FormatDate";
import parse from "html-react-parser";
import urlify from "../../utils/handleUrl";
import { BsFillReplyAllFill } from "react-icons/bs";

export default function Post(props: any) {
    const commentButton = useRef<any>(null);
    useLayoutEffect(() => {
        const currentTriggerEditorButton = commentButton.current;
        currentTriggerEditorButton.addEventListener("click", () => {
            eventEmitter.emit("editor-up", { action: "comment", on: props.id, to: props.user.email });
        });

        return () => {
            currentTriggerEditorButton.removeEventListener("click", () => {
                eventEmitter.emit("editor-up", { action: "comment", on: props.id, to: props.user.email });
            });
        };
    }, []);

    return (
        <article
            className={`
        bg-white p-4 rounded-md
        hover:shadow-md
        duration-300
        sm:flex sm:items-start mx-2
      `}
        >
            <div className={"flex-shrink-0"}>
                <Avatar name={props.user.email} size="sm" />
            </div>

            <div className={"flex-grow flex flex-col ml-3"}>
                <div className={"flex items-center gap-2"}>
                    <span className={"text-sm font-semibold"}>{censorEmail(props.user.email)}</span>
                    <span className={"text-gray-400 text-xs"}>{formatDate(props.createdAt)}</span>
                </div>
                <h3 className={"text-base font-medium mt-1"}>{props.title}</h3>
                <p className={"text-sm mt-1 max-w-[700px] break-words"}>{parse(urlify(props.content))}</p>
                <div className={"flex justify-between items-center mt-3"}>
                    <div className={"flex items-center gap-1"}>
                        <span className={"text-xs text-gray-400"}>{props.comments.length}</span>
                        <span className={"text-gray-400 text-xs"}>Comments</span>
                    </div>
                    <div className={"flex gap-2 items-center"}>
                        <div className="group">
                            <Button ref={commentButton} buttonType={"icon-border"} className={"duration-300 font-normal flex"}>
                                <span>
                                    <BsFillReplyAllFill className="text-black font-bold" />
                                </span>
                                <span className="text-sm text-black ml-1">Reply</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}
