interface InputProps
{
    label?: string;
    children?: any;
    className?: any;
    inputType: string;
    placeHolder?: string;
    type?: string;
    onChange?: Function;
    defaultValue?: string
}


const InputType =
{
    "with-icon": "py-2.5 px-4"
}

export default function Input(props: InputProps)
{
    return (
        <label htmlFor="" className={`
            ${props.className}
            ${InputType[props.inputType as keyof typeof InputType]}
            flex items-center gap-2 bg-c-H rounded-3xl border-gray-300 border
        `}>
            {props.children}
            <input onChange={props.onChange as any}
                   defaultValue={props.defaultValue}
                   className={"bg-transparent border-0 w-full outline-0 text-[13px]"}
                   placeholder={props.placeHolder}
                   type={props.type}/>
        </label>
    );
}
