import { useEffect, useRef, useState } from "react";
import { BsChevronDown, BsFillStickiesFill } from "react-icons/bs";
import eventEmitter from "../../utils/EventEmitter";

export default function RankPosts(
    { questions, posts, setPosts }:
        { questions: any, posts: any[], setPosts: React.Dispatch<React.SetStateAction<any[]>> }
) {
    const [isSortedByComments, setSortedByComments] = useState('');
    const detailsRef = useRef<HTMLDetailsElement>(null);

    const resetDetails = () => {
        if (detailsRef.current) detailsRef.current.removeAttribute('open');
    };

    const handleClear = () => {
        setSortedByComments('');
        resetDetails();
        setPosts(questions);
    };

    useEffect(() => {
        const listener = eventEmitter.addListener("reload-posts", () => {
            handleClear();
        })

        return () => {
            listener.removeListener("reload-posts")
        }

    }, [])

    const handleSort = () => {
        resetDetails();
        const pinnedPosts = posts.filter(post => post.isPinned);
        const unpinnedPosts = posts.filter(post => !post.isPinned);

        const sortedRecentPosts = [...unpinnedPosts].sort((a, b) => {
            const specialTitle = "I have just signed on to use the Free Version of PinyinTube!";
            
            if (a.isPinned && !b.isPinned) return -1;
            if (!a.isPinned && b.isPinned) return 1;
            if (a.isPinned && b.isPinned) return 0;

            if (a.title.includes(specialTitle) && !b.title.includes(specialTitle)) return 1;
            if (!a.title.includes(specialTitle) && b.title.includes(specialTitle)) return -1;

            if (isSortedByComments !== '') {
                const commentComparison = isSortedByComments === "High to low"
                    ? b.comments.length - a.comments.length
                    : a.comments.length - b.comments.length;

                if (commentComparison !== 0) return commentComparison;
            }

            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB.getTime() - dateA.getTime();
        });

        setPosts([...pinnedPosts, ...sortedRecentPosts]);
    };

    useEffect(() => {
        if (isSortedByComments !== '') {
            handleSort();
        }
    }, [isSortedByComments]);

    return (
        <div className="mb-5 flex p-2 md:w-full md:justify-end md:items-end">
            <details ref={detailsRef} className="w-full md:w-fit">
                <summary className="btn rounded px-4 py-2 flex gap-2 border border-solid border-gray-300 hover:bg-gray-200">
                    <span className="flex justify-center items-center w-3 text-c-F">
                        <BsFillStickiesFill />
                    </span>
                    <p>
                        Sort by Comments
                        {isSortedByComments && (
                            <span>
                                :
                                <span className="bg-c-F text-c-G rounded-2xl px-3 py-1 ms-1">
                                    {isSortedByComments}
                                </span>
                            </span>
                        )}
                    </p>
                    <span className="flex justify-center items-center w-3 text-c-F"><BsChevronDown /></span>
                </summary>
                <ul className="bg-white rounded shadow-lg absolute mt-2 w-48 p-2 border border-gray-300">
                    <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => { setSortedByComments('High to low'); }}>
                        High to low
                    </li>
                    <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => { setSortedByComments('Low to high'); }}>
                        Low to high
                    </li>
                    <li className="p-2 flex items-center justify-center cursor-pointer" onClick={() => { handleClear(); }}>
                        <span className="text-center text-c-G font-semibold bg-c-F rounded-3xl p-2 my-auto w-1/2">Clear</span>
                    </li>
                </ul>
            </details>
        </div>
    )
}
