import { useReducer } from 'react';
import QuestionsContext from './QuestionsContext';
import { initialQuestionsState, questionsReducer } from './QuestionsReducer';

function QuestionsProvider(props: any) {
  const [questions, dispatchQuestions] = useReducer(
    questionsReducer,
    initialQuestionsState
  );

  return (
    <QuestionsContext.Provider value={[questions, dispatchQuestions]}>
      {props.children}
    </QuestionsContext.Provider>
  );
}

export default QuestionsProvider;
