import { useLayoutEffect, useRef, useState } from "react";
import Avatar from "../core/Avatar";
import urlify from "../../utils/handleUrl";
import parse from "html-react-parser";
import formatDate from "../../utils/FormatDate";
import CommentContainer from "../containers/CommentContainer";
import eventEmitter from "../../utils/EventEmitter";
import Button from "../core/Button";
import { BsFillReplyAllFill } from "react-icons/bs";
import { RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";
import truncateEmail from "../../utils/handleEmail";
import axios from "axios";

export default function PostCard(props: any) {
    const commentButton = useRef<any>(null);
    useLayoutEffect(() => {
        const currentTriggerEditorButton = commentButton.current;

        currentTriggerEditorButton.addEventListener("click", () => {
            eventEmitter.emit("editor-up", { action: "comment", on: props.id, to: props.email });
        });

        return () => {
            currentTriggerEditorButton.removeEventListener("click", () => {
                eventEmitter.emit("editor-up", { action: "comment", on: props.id, to: props.email });
            });
        };
    }, []);

    const [showComments, setShowComments] = useState(false);
    const handleCommentClick = () => {
        setShowComments(!showComments);
    };

    const handlePined = async (isPinned: boolean, id: any) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/posts/${id}/${!isPinned}/togglePinned`
            );
            eventEmitter.emit("reload-posts")
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <article className={`
                bg-white px-5 py-3 rounded-md 
                hover:shadow-md
                border 
                duration-300
                block
                sm:flex-row sm:items-start mx-2
            `}>
            <section className={"flex items-center gap-3 pb-2 text-sm sm:text-base "}>
                <Avatar name={props.email} />
                <div className="flex flex-col">
                    <span className={"font-semibold w-[130px]"}>{truncateEmail(props.email)}</span>
                    <span className={"text-xs text-gray-400"}>{formatDate(props.createdAt)}</span>
                </div>
                <div className="flex-grow h-6 flex items-end justify-end">
                    {props.isPinned ?
                        <span className="cursor-pointer text-c-F flex items-center group hover:bg-c-F hover:text-white hover:px-2 hover:py-2 hover:rounded-md transition-all duration-500" onClick={() => handlePined(props.isPinned, props.id)}>
                            <RiPushpin2Fill className="w-6 h-auto me-1" />
                            <span className="opacity-0 max-w-0 overflow-hidden group-hover:opacity-100 group-hover:max-w-xs transition-all duration-500 text-white">Pinned</span>
                        </span>
                        : <span className="cursor-pointer text-c-F flex items-center group hover:bg-c-F hover:text-white hover:px-2 hover:py-2 hover:rounded-md transition-all duration-500" onClick={() => handlePined(props.isPinned, props.id)}>
                            <RiPushpin2Line className="w-6 h-auto me-1" />
                            <span className="opacity-0 max-w-0 overflow-hidden group-hover:opacity-100 group-hover:max-w-xs transition-all duration-500 text-white">Pin</span>
                        </span>
                    }
                </div>
            </section>

            <section className={"flex flex-col overflow-hidden gap-3 w-full sm:pl-[3.25rem]"}>
                <h3 className={"text-[14px] pl-1 capitalize break-words font-medium"}>{props.title}</h3>
                <p className={"pl-1 lg:max-w-lg break-words text-[13px]"}>{parse(urlify(props.content))}</p>
                <div className={"flex justify-between items-center gap-3"}>
                    <div className={"flex items-center"}>
                        <Button ref={commentButton} buttonType={"icon-border"} className={"duration-300 group-hover:bg-c-F font-semibold flex"}>
                            <span>
                                <BsFillReplyAllFill className="text-gray-600 font-bold" />
                            </span>
                            <span className="text-sm text-gray-600 ml-1">Reply</span>
                        </Button>
                        <span className={"text-gray-600 cursor-pointer underline"} onClick={handleCommentClick}>{props.commentCount} comments</span>
                    </div>
                </div>

                {showComments && (
                    <div className="mt-4">
                        <CommentContainer postId={props.id} />
                    </div>
                )}
            </section>

        </article>

    );
}
