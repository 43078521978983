import EventCard from "../components/EventCard";


export default function EventCardContainer()
{
    return (
        <div className={`mx-auto pt-2 lg:max-w-[700px] xl:w-full mb-7 flex gap-5 xl:flex-col`}>
            <EventCard/>
        </div>
    );
}
