import ReactAvatar from 'react-avatar';


export default function Avatar(props: any)
{
    return (
        <div className={"w-[45px] overflow-hidden rounded-full"}>
            <ReactAvatar size={"45"} name={props.name}/>
        </div>
    );
}
