import Comment from "../components/Comment";


export default function ReplyContainer(props: any)
{
    return (
        <div className={props.className + " mt-5 flex flex-col gap-5"}>
            {
                props.replies.length !== 0
                ? props.replies.map((reply: any, index: number) => {
                    return <Comment key={reply.id} action={"reply"} commentId={props.id} {...reply} className={"rounded-xl"}/>
                 })
                : <></>
            }
        </div>
    );
}
