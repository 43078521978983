const truncateEmail = (email: string): string => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(email)) {
    return email;
  }

  const [username] = email.split('@');
  const truncatedPart = "*".repeat(3);
  const truncatedUsername = email.substring(0, Math.ceil(username.length / 2));
  return truncatedUsername + truncatedPart;
};

export default truncateEmail;
