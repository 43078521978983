import Input from "../core/Input";
import {ChangeEvent, useReducer} from "react";
import TextArea from "../core/TextArea";
import Button from "../core/Button";

import {useRef, useState, useEffect} from "react"

import eventEmitter from "./../../utils/EventEmitter"
import {post} from "../../utils/MyAxios"



const initialEditorState =
{
    submitAction: null,
    to: "",
    on: "",
    at: "",
    docs: ""
}

function checkInput(state: any, requiredFields: Array<string>) {
    const errors: { [key: string]: string } = {};

    requiredFields.forEach(field => {
        if (!state.docs[field]?.trim()) {
            errors[field] = `Please fill your ${field}.`;
        } else if (state.docs[field]?.trim().length < 2) {
            errors[field] = `Your ${field} must be at least 2 characters.`;
        }
    });
    return Object.keys(errors).length > 0 ? errors : null;
};

function submitPost(state: any)
{
    return () => {
        const requiredFields = ["title", "email", "content"];
        const result = checkInput(state, requiredFields);
        if (result !== null) {
            return result;
        }

        localStorage.setItem("userEmail", state.docs.email)
        post("posts", {title: state.docs.title, content: state.docs.content}, {email: state.docs.email})
            .then((res) => {
                window.location.reload()
            })
    }
}


function submitComment(state: any)
{
    return () => {
        const requiredFields = ["email", "content"];
        const result = checkInput(state, requiredFields);
        if (result && Object.keys(result).length > 0) {
            return result;
        }

        localStorage.setItem("userEmail", state.docs.email)
        post(`posts/${state.on}/comments`, {content: state.docs.content}, {email: state.docs.email})
            .then((res) => {
                eventEmitter.emit("reload-comment")
            })
    }
}


function submitReply(state: any)
{
    return () => {
        const requiredFields = ["email", "content"];
        const result = checkInput(state, requiredFields);
        if (result && Object.keys(result).length > 0) {
            return result;
        }

        localStorage.setItem("userEmail", state.docs.email)
        post(`comments/${state.at}/replies`, {content: state.docs.content}, {email: state.docs.email})
            .then((res) => {
                eventEmitter.emit("reload-comment")
            })
    }
}

function editorReducer(state: any, action: any)
{
    switch (action.type)
    {
        case "post":
            state.submitAction = submitPost(state)
            state.to = ""
            state.on = ""
            state.at = ""
            return state

        case "comment":
            state.submitAction = submitComment(state)
            state.to = action.to
            state.on = action.on
            state.at = "action.at"
            return state

        case "reply":
            state.submitAction = submitReply(state)
            state.to = action.to
            state.on = action.on
            state.at = action.at
            return state

        case "setDocs":
            state.docs = action.docs
            return state
        default:
            return {...state}
    }
}


export default function PostEditor()
{

    const userEmail = localStorage.getItem("userEmail") || "";
    const [editor, dispatchEditor] = useReducer(editorReducer, initialEditorState)
    const [docs, setDocs] = useState({
        email: userEmail,
        title: "",
        content: ""
    });
    const editorRef = useRef<any>(null)
    const [isPost, setIsPost] = useState(false)
    const [error, setError] = useState({title: "", email: "", content: ""})


    useEffect(() =>
    {
        const editorListener = eventEmitter.addListener('editor-up', (data: any) =>
        {
            dispatchEditor({type: data.action, to: data.to, on: data.on, at: data.at})
            showEditor()
        })

        return () => {
            editorListener.removeListener('editor-up')
        }

    }, [])


    useEffect(() => {
        dispatchEditor({type: "setDocs", docs: docs})
    }, [docs])

    const [isEditorVisible, setEditorVisible] = useState(false);
    useEffect(() => {
        if (isEditorVisible) {
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [isEditorVisible]);

    function hideEditor()
    {
        const currentEditorRef = editorRef.current
        currentEditorRef.classList.remove('bottom-12')
        currentEditorRef.classList.add('-bottom-full')
        setEditorVisible(false)
        setError({title: "", email: "", content: ""});
        setDocs((pre) => ({ ...pre, title:"", content: "" }))

    }


    function showEditor()
    {
        setIsPost(pre => !pre)
        const currentEditorRef = editorRef.current
        currentEditorRef.classList.remove('-bottom-full')
        currentEditorRef.classList.add('bottom-12')
        setEditorVisible(true)
    }


    function submit()
    {
        setError({title: "", email: "", content: ""});

        const result = editor.submitAction()
        if (result !== undefined) {
            setError(result);
            return;
        }
        
        hideEditor()
        setDocs((pre) => ({ ...pre, title:"", content: "" }))
    }


    return (
        <>
            {isEditorVisible && (
                <div className="absolute top-0 left-0 w-full h-full z-40 bg-black opacity-70"></div>
            )}
            <form
                ref={editorRef}
                className={
                    "fixed duration-700 left-1/2 -translate-x-1/2 flex flex-col w-11/12 bg-white shadow-md gap-4 p-4 mx-auto rounded-3xl -bottom-full z-50 md:w-[700px]"
                }
            >
                <Input
                    defaultValue={docs.email}
                    onChange={(event: any) =>
                        setDocs((pre) => ({ ...pre, email: event.target.value }))
                    }
                    className={"w-full bg-white-100"}
                    type={"email"}
                    inputType={"with-icon"}
                    placeHolder="YourEmail@gmail.com"
                >
                    <span className={"uppercase font-bold text-gray-400"}>email:</span>
                </Input>
                <span className={`text-red-500 font-semibold ${error.email === "" || error.email === undefined ? "hidden" : ""}`}>{error.email}</span>
                <span className='pl-5 text-black'>
                    * Please use{' '}
                    <span className={'font-bold text-c-F'}>Your Own Email</span>, so
                    that you will get notified when someone replies to your post or
                    comment.
                </span>
                {!editor.to && (
                    <Input
                        defaultValue={docs.title}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setDocs((pre) => ({ ...pre, title: event.target.value }))
                        }
                        className={'w-full bg-white-100'}
                        inputType={'with-icon'}
                    >
                        <span className={'uppercase font-bold text-gray-400'}>
                            {editor.to ? 'reply' : 'title'}:
                        </span>
                    </Input>
                )}
                <span className={`text-red-500 font-semibold ${error.title === "" || error.title === undefined ? "hidden" : ""}`}>{error.title}</span>

                <TextArea
                    defaultValue={docs.content}
                    onChange={(event: any) =>
                        setDocs((pre) => ({ ...pre, content: event.target.value }))
                    }
                    className={"w-full bg-white-100"}
                    type={"with-icon"}
                >
                    <span className={"uppercase font-bold text-gray-400"}>CONTENT:</span>
                </TextArea>
                <span className={`text-red-500 font-semibold ${error.content === "" || error.content === undefined ? "hidden" : ""}`}>{error.content}</span>

                <section className={"flex gap-7"}>
                    <Button
                        type={"reset"}
                        onClick={hideEditor}
                        buttonType={"outline"}
                        className={
                            "w-full py-4 border-none bg-gray-200 uppercase font-black"
                        }
                        label={"cancel"}
                    />
                    <Button
                        type={"reset"}
                        onClick={submit}
                        buttonType={"outline"}
                        className={
                            "bg-c-F py-4 border-none w-full border-c-A text-white uppercase font-black"
                        }
                        label={"submit"}
                    />
                </section>
            </form>

        </>
    );
}
